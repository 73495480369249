<template>
  <ValidationObserver
    v-if="!submitted"
    tag="form"
    ref="observer"
    class="step"
    @submit.prevent="validateBeforeSubmit()">
    <!--Title-->
    <div class="step__title">
      <h4>ご登録情報を入力してください</h4>
      <p>ご登録には<a :href="hostName + '/term'" target="_blank" class="text-green">利用規約</a>・<a target="_blank" :href="hostName + '/policy'" class="text-green">個人情報の取扱いについて</a>への同意が必要です。</p>
    </div>

    <!--Error server-->
    <ul v-if="errors.length"
        class="error__server">
      <li v-for="(err, index) in errors"
          :key="index"
          v-text="err"/>
    </ul>

    <!--Step form-->
    <div class="step__form">
      <div class="step__form__group">
        <!--name-->
        <div class="form__control">
          <label>
            ニックネーム
            <span class="required"
                  v-text="'必須'"/>
          </label>

          <div class="form__control__input">
            <span class="required"
                  v-text="'必須'"/>

            <InputField v-model="form.nickname"
                        rules="required|max:10"
                        class="w-100"
                        field="ニックネーム"
                        vid="nickname"/>
          </div>
        </div>

        <!--email-->
        <div class="form__control">
          <label>
            メールアドレス
            <span class="required"
                  v-text="'必須'"/>
          </label>

          <div class="form__control__input">
            <span class="required"
                  v-text="'必須'"/>

            <InputField v-model="form.email"
                        rules="required|email|max:200"
                        class="w-100"
                        field="メールアドレス"
                        vid="email"
                        :disabled="!!email"/>
          </div>
        </div>

        <!--secret_question-->
        <div class="form__control">
          <label>
            秘密の質問
            <span class="required"
                  v-text="'必須'"/>
          </label>

          <div class="form__control__input">
            <span class="required"
                  v-text="'必須'"/>

            <InputSelect v-model.number="form.secret_question"
                        rules="required"
                        field="秘密の質問"
                        vid="secret_question"
                        class="w-50 errors-nowrap secret-question"
                        :options="OPTION_SECRET_QUESTION"/>
          </div>
        </div>

        <!--secret_answer-->
        <div class="form__control">
          <label>
            秘密の答え
            <span class="required"
                  v-text="'必須'"/>
          </label>

          <div class="form__control__input">
            <span class="required"
                  v-text="'必須'"/>

            <InputField v-model="form.secret_answer"
                        class="w-100"
                        :rules="form.secret_question ? 'required|minmax:2,10|only_hiragana' : ''"
                        field="秘密の答え"
                        :disabled="!form.secret_question"
                        vid="secret_answer"/>
          </div>
        </div>
      </div>
     <div class="tabs-skip-info">
          <div class="tab-skip-info">
            <input type="checkbox" id="chck1">
            <label class="tab-label-skip-info" for="chck1">
            <div class="desc-skip-regis">
              <p>会員登録時には以下の項目を省略することができます。</p>
              <p>ポイント交換時には登録が必須になります。</p>
              <p>紹介コードをお持ちの方はこのタイミングで必ずご入力ください。</p>
            </div>
          </label>
            <div class="tab-content-skip-info">
                <div class="step__form__group">
          <!--gender-->
          <div class="form__control form__control--specify mb-4">
            <label>
              性別
            </label>

            <div class="form__control__input">

              <div class="radio__field no-required">
                <div v-for="item in OPTION_GENDER"
                    :key="item.id">
                  <input v-model="form.gender"
                        type="radio"
                        :id="item.text"
                        :value="item.value">
                  <label :for="item.text"
                        v-text="item.text"
                        class="ml-2"/>
                </div>
              </div>
            </div>
          </div>

          <!--birthday-->
          <div class="form__control">
            <label>
              生年月日
            </label>

            <div class="form__control__input">
              <InputField v-model="form.birthday"
                          @focusout="handleFocusout()"
                          @focusin="handleFocusin()"
                          field="生年月日"
                          vid="birthday"
                          placeholder="例）19900701"
                          class="w-30 postal_code errors-nowrap no-required"/>
            </div>
          </div>

          <!--postal_code-->
          <div class="form__control">
            <label>
              郵便番号
            </label>

            <div class="form__control__input">

              <InputField v-model="form.postcode"
                          class="w-30 postal_code errors-nowrap no-required"
                          rules="numeric|postCodeNum:7"
                          field="郵便番号"
                          vid="postcode"
                          :max-length="7"/>
            </div>
          </div>
                </div>
                <div class="step__form__group">
                  <!--career-->
                  <div class="form__control form__control--select ">
                    <label v-text="'職業区分'"/>

                    <div class="form__control__input">
                      <InputSelect v-model.number="form.career"
                                  field="職業区分"
                                  vid="career"
                                  class="w-50 no-required"
                                  :options="OPTION_CAREER"/>
                    </div>
                  </div>

                  <!--income_of_family-->
                  <div class="form__control form__control--select ">
                    <label v-text="'世帯年収'"/>

                    <div class="form__control__input">
                      <InputSelect v-model.number="form.income_of_family"
                                  field="世帯年収"
                                  vid="income_of_family"
                                  class="w-50 no-required"
                                  :options="OPTION_INCOME_OF_FAMILY"/>
                    </div>
                  </div>

                  <!--marriage_status-->
                  <div class="form__control form__control--specify mb-4">
                    <label v-text="'未既婚'"/>

                    <div class="form__control__input">
                      <div class="radio__field no-required">
                        <div v-for="item in OPTION_MARRIAGE"
                            :key="item.id">
                          <input v-model="form.marriage_status"
                                type="radio"
                                :id="item.text"
                                :value="item.value">
                          <label :for="item.text"
                                v-text="item.text"
                                class="ml-2"/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--person_living_together-->
                  <div class="form__control">
                    <label v-text="'同居人数'"/>

                    <div class="form__control__input">
                      <InputField v-model.number="form.person_living_together"
                                  class="w-30 no-required errors-nowrap"
                                  rules="only_number_halfwidth|max:15"
                                  field="同居人数"
                                  vid="person_living_together"
                                  :max-length="15"/>
                      <span class="people">人</span>
                    </div>
                  </div>

                  <!--residence_status-->
                  <div class="form__control form__control--select ">
                    <label v-text="'居住形態'"/>

                    <div class="form__control__input">
                      <InputSelect v-model.number="form.residence_status"
                                  field="居住形態"
                                  vid="residence_status"
                                  class="w-50 no-required"
                                  :options="OPTION_RESIDENCE"/>
                    </div>
                  </div>

                  <!--interest-->
                  <div class="form__control form__control--select ">
                    <label v-text="'興味のある分野'"/>

                    <div class="form__control__input">
                      <div class="checkbox__field">
                        <div v-for="item in OPTION_INTEREST" :key="item.id">
                          <input v-model="form.interest" type="checkbox" :id="item.id" :value="item.value">
                          <label :for="item.id">{{ item.text }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="step__form__group">
                  <!--invite_code-->
                  <div class="form__control">
                    <label v-text="'紹介コード'"/>

                    <div class="form__control__input">
                      <InputField v-model="form.invite_code"
                                  class="w-100 no-required"
                                  placeholder="お持ちの方はご入力ください"
                                  field="紹介コード"
                                  :disabled="this.$store.state.user.invite_code ? true : false"
                                  vid="invite_code"
                                  customPlaceholder
                                  />
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </div>

    <!-- TERM AND POLICY -->
    <TermPolicy />

    <!--confirm rules-->
    <div class="rule">
      <ValidationProvider
        v-slot="{ errors }"
        tag="div"
        name="policy"
        vid="policy"
        rules="required">
        <div class="rule__check">
          <input v-model="read_policy"
                 type="checkbox"
                 id="check"
                 :true-value="true"
                 :false-value="null">
          <label for="check"
                 v-text="'「利用規約」「個人情報の取扱いについて」を確認したので同意します。'"/>
        </div>

        <p v-if="errors[0]"
           class="err__policy">「利用規約」「個人情報の取り扱いについて」を確認してチェックしてください。</p>
      </ValidationProvider>
    </div>

    <!--button submit-->
    <button type="submit"
            class="btn btn__submit">
      確認画面へ
      <ChevronRightIcon size="24"/>
    </button>
  </ValidationObserver>

  <div v-else class="step">
    <!--Title-->
    <div class="step__title">
      <h4>ご登録情報をご確認ください</h4>
      <p>入力内容を確認してください。<br>
        内容をご確認の上、よろしければ「登録」ボタンを押してください。</p>
    </div>

    <!--Step form-->
    <div class="step__form">
      <div class="step__form__group">
        <div class="item">
          <label v-text="'ニックネーム'"/>

          <p v-text="form.nickname || '－'"/>
        </div>

        <div class="item">
          <label v-text="'メールアドレス'"/>

          <p v-text="form.email"/>
        </div>
      </div>
      <div class="step__form__group">
        <div class="item">
          <label v-text="'性別'"/>

          <p>{{ form.gender | findTextEnum(OPTION_GENDER)}}</p>
        </div>

        <div class="item">
          <label v-text="'生年月日'"/>
          <p v-text="form.birthday || '－'"/>

          <!-- <p>{{ form.birthday }}</p> -->
        </div>

        <div class="item">
          <label v-text="'郵便番号'"/>
          <p v-if="form.postcode">{{ form.postcode | formatPostalCode }}</p>

          <p v-else>－</p>
          <!-- <p>{{ form.postcode | formatPostalCode }}</p> -->
        </div>
      </div>
      <div class="step__form__group">
        <div class="item">
          <label v-text="'秘密の質問'"/>

          <p>{{ form.secret_question | findTextEnum(OPTION_SECRET_QUESTION) }}</p>
        </div>

        <div class="item">
          <label v-text="'秘密の答え'"/>
          <p v-text="form.secret_answer || '－'"/>

          <!-- <p v-text="form.secret_answer"/> -->
        </div>
      </div>
      <div class="step__form__group">
        <div class="item">
          <label v-text="'職業区分'"/>

          <p>{{ form.career | findTextEnum(OPTION_CAREER) }}</p>
        </div>

        <div class="item">
          <label v-text="'世帯年収'"/>

          <p>{{ form.income_of_family | findTextEnum(OPTION_INCOME_OF_FAMILY) }}</p>
        </div>

        <div class="item">
          <label v-text="'未既婚'"/>

          <p>{{ form.marriage_status | findTextEnum(OPTION_MARRIAGE) }}</p>
        </div>

        <div class="item">
          <label v-text="'同居人数'"/>

          <p v-text="form.person_living_together ? (form.person_living_together + '人') : '－'"/>
        </div>

        <div class="item">
          <label v-text="'居住形態'"/>

          <p>{{ form.residence_status | findTextEnum(OPTION_RESIDENCE) }}</p>
        </div>

        <div class="item">
          <label v-text="'興味のある分野'"/>

          <ul>
            <template v-if="form.interest.length">
              <li v-for="(item, index) in form.interest" :key="index">{{OBJECT_INTEREST[item]}}</li>
            </template>
            <template v-else>
              －
            </template>
          </ul>
        </div>
      </div>
      <div class="step__form__group">
        <div class="item">
          <label v-text="'紹介コード'"/>

          <p v-text="form.invite_code || '－'"/>
        </div>
      </div>
    </div>

    <!--button submit-->
    <div class="btn__group">
      <a @click.prevent="submitted = false"
         href="#"
         class="btn btn__back">
        <ChevronLeftIcon size="24"/>
        入力画面へ戻る
      </a>

      <button @click.prevent="handleSubmit()"
              class="btn btn__submit">
        上記内容で登録する
        <ChevronRightIcon size="24"/>
      </button>
    </div>
  </div>
</template>

<script>
// Store
import store from '@/store'
import Cookie from 'js-cookie'
import { mapActions, mapState } from 'vuex'
// Components
import InputField from '@/components/form/InputField'
import InputSelect from '@/components/form/InputSelect'

// Other
import {
  OPTION_CAREER,
  OPTION_GENDER,
  OPTION_INCOME_OF_FAMILY, OPTION_INTEREST,
  OPTION_MARRIAGE,
  OPTION_RESIDENCE,
  OPTION_SECRET_QUESTION,
  OBJECT_INTEREST
} from '@/enum/options'
import { giftCodeStorageKey } from '@/enum/gift-code'
import { ChevronRightIcon, ChevronLeftIcon } from 'vue-feather-icons'
import CommonMixin from '@/mixins/common.mixin'
import TermPolicy from '@/components/TermPolicy'
import {
  checkBirthdayInvalid,
  checkDateInvalid,
  formatDateToString,
  checkWebview,
  getDohBoParams,
  removeDohBoParams,
  saveDohBoParam
} from '@/utils/helpers'
import moment from 'moment'
import { SNS_SERVICE } from '@/enum/sns-service'
import { getExchangeServiceRoutes } from '@/utils/exchange-service'
import { ERROR_CODE } from '@/enum/error-code'
import { RESPONSE_STATUS } from '@/enum/response'

export default {
  name: 'Step02',

  components: {
    TermPolicy,
    InputSelect,
    InputField,
    ChevronRightIcon,
    ChevronLeftIcon
  },

  mixins: [CommonMixin],

  data () {
    return {
      form: {
        nickname: '',
        email: '',
        gender: 3,
        birthday: '',
        postcode: '',
        secret_question: null,
        secret_answer: '',
        career: null,
        income_of_family: null,
        marriage_status: 3,
        person_living_together: null,
        residence_status: null,
        interest: [],
        invite_code: '',
        token: ''
      },
      submitted: false,
      read_policy: null,
      errors: [],
      hostName: window.location.origin,
      OPTION_GENDER,
      OPTION_SECRET_QUESTION,
      OPTION_CAREER,
      OPTION_INCOME_OF_FAMILY,
      OPTION_MARRIAGE,
      OPTION_RESIDENCE,
      OPTION_INTEREST,
      OBJECT_INTEREST
    }
  },

  async beforeRouteEnter (to, from, next) {
    // check login guard
    if (store.state.auth.token || checkWebview()) {
      // check login line from exchange-service line pay
      // because line pay use api login sns only callback to register, check to redirect exchange-service
      const previousRoute = localStorage.getItem('previous_route')
      const exchangeServiceRoutes = getExchangeServiceRoutes()
      if (exchangeServiceRoutes.indexOf(previousRoute) !== -1) {
        const pointExchange = localStorage.getItem('point_exchange')

        // remove section
        // localStorage.removeItem('previous_route')
        // localStorage.removeItem('point_exchange')
        return next({
          name: previousRoute,
          query: {
            code: to.query.code || '',
            state: to.query.state || '',
            point_exchange: pointExchange || ''
          }
        })
      } else return next({ name: 'index' })
    }

    // check route history to back page before
    const routeHistory = localStorage.getItem('route-history') || null

    const dohBoParams = getDohBoParams()
    const hasDohBoParams = Object.keys(dohBoParams).length !== 0

    let params = {
      sns_service: to.params.service || '',
      code: to.query.code || '',
      state: to.query.state || ''
    }

    if (hasDohBoParams) {
      params = {
        ...params,
        ...dohBoParams
      }
    }

    const loginResponse = await store.dispatch('auth/userLoginSNS', [to.params.service, params])

    if (loginResponse.success) {
      const snsService = params.sns_service
      const haveRegisterToken = loginResponse.data.registration_token
      if (haveRegisterToken && (snsService === SNS_SERVICE.google || snsService === SNS_SERVICE.yahoo)) {
        if (snsService === SNS_SERVICE.google) store.dispatch('auth/setGoogleLoginErrorStatus', true)
        else if (snsService === SNS_SERVICE.yahoo) store.dispatch('auth/setYahooLoginErrorStatus', true)

        store.dispatch('auth/setMessageError', '登録のないアカウントです。')
        return next({ name: 'Login' })
      }

      const { access_token: accessToken, registration_token: registrationToken } = loginResponse.data

      if (registrationToken) {
        const dohBoParams = getDohBoParams(loginResponse.data)
        const hasDohBoParam = Object.keys(dohBoParams).length !== 0

        if (hasDohBoParam) {
          removeDohBoParams()
        }

        Object.entries(dohBoParams).forEach(([param, value]) => {
          saveDohBoParam(param, value)
        })

        next(vm => { vm.form.token = registrationToken })
      } else {
        if (accessToken && hasDohBoParams) {
          const dohBoParams = getDohBoParams()
          const handleParamResponse = await store.dispatch('dohBo/handleDohBoParam', dohBoParams)

          if (handleParamResponse.status === RESPONSE_STATUS.OK) {
            removeDohBoParams(dohBoParams)
          }
        }

        const callBackDotMoney = sessionStorage.getItem('callBackDotMoney') || null

        if (callBackDotMoney) {
          const urlRedirect = callBackDotMoney
          sessionStorage.removeItem('callBackDotMoney')

          // login dot money before redirect
          const exchangeDotMoneyResponse = await store.dispatch('dotMoney/confirmExchange')

          if (exchangeDotMoneyResponse.status === RESPONSE_STATUS.OK) {
            const { status_code: statusCode } = exchangeDotMoneyResponse.data

            if (statusCode === RESPONSE_STATUS.OK) {
              store.dispatch('dotMoney/loginDotMoney').then((res) => {
                window.open(res.data, '_self')
              })
            } else if (statusCode === RESPONSE_STATUS.NOT_FOUND) {
              window.open(urlRedirect, '_self')
            }
          }
        } else if (routeHistory) {
          localStorage.removeItem('route-history')

          // back to route before
          next({ path: routeHistory })
        } else {
          const giftCode = Cookie.get(giftCodeStorageKey) || null
          Cookie.remove(giftCodeStorageKey)

          if (giftCode) {
            next({
              name: 'GiftCodeDetail',
              params: {
                code: giftCode
              }
            })
          } else {
            next({ name: 'Index' })
          }
        }
      }
    } else {
      const previousRoute = sessionStorage.getItem('previous_route')

      if (loginResponse.status === RESPONSE_STATUS.BAD_REQUEST) {
        if (loginResponse?.error?.code === ERROR_CODE.lineAccountAlreadyRegistered) {
          await store.commit('auth/SET_LINE_REGISTERED_MESSAGE_ERR', loginResponse.error.message)
          next({ name: previousRoute })
        } else {
          next({ name: 'Expired' })
        }
      } else {
        next({
          name: loginResponse.error.message === '既に登録済みのメールアドレスです。' ? (previousRoute || 'Register') : 'Login'
        })
      }
    }
  },

  created () {
    if (sessionStorage.getItem('invite_code')) {
      this.form.invite_code = sessionStorage.getItem('invite_code')
    }
    this.form.email = this.email
    this.form.token = this.token
  },

  computed: {
    ...mapState('user', ['email']),
    ...mapState('auth', ['token'])
  },

  watch: {
    submitted: {
      handler () {
        this.scrollToTop()
      }
    }
  },

  methods: {
    // Action
    ...mapActions('user', ['userRegisterSNS']),
    ...mapActions('dohBo', ['handleDohBoParam']),

    handleFocusout () {
      // validate field birthday
      if (!this.form.birthday) {
        // this.$refs.observer.setErrors({
        //   birthday: '生年月日を入力してください。'
        // })
      } else if (checkBirthdayInvalid(this.form.birthday)) {
        this.$refs.observer.setErrors({
          birthday: '半角数字8桁で入力してください。'
        })
      } else {
        // check field birthday is invalid date
        if (checkDateInvalid(this.form.birthday)) {
          this.$refs.observer.setErrors({
            birthday: '正しい日付を入力してください。'
          })
        } else this.form.birthday = moment(this.form.birthday).format('YYYY年MM月DD日')
      }
    },

    handleFocusin () {
      this.form.birthday = this.form.birthday.replace(/\D/g, '')
    },

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.submitted = true
      }
      setTimeout(() => {
        this.scrollToTop()
      }, 10)
    },

    async handleSubmit () {
      this.form = {
        ...this.form,
        // format date before submit API
        birthday: this.form.birthday ? formatDateToString(this.form.birthday) : null,
        postcode: this.form.postcode ? this.form.postcode : null,
        secret_answer: this.form.secret_answer ? this.form.secret_answer : null,
        skip_regis_info: +(!this.form.secret_answer || !this.form.postcode || !this.form.birthday || !this.form.secret_question)
      }

      const registerResponse = await this.userRegisterSNS(this.form)

      sessionStorage.getItem('invite_code') && sessionStorage.removeItem('invite_code')

      if (registerResponse.status === RESPONSE_STATUS.OK) {
        // set info to login
        await store.commit('auth/SET_TOKEN', registerResponse.data.data.access_token)
        await store.commit('auth/SET_PROFILE', registerResponse.data.data.user)

        const dohBoParams = getDohBoParams()
        const hasDohBoParams = Object.keys(dohBoParams).length !== 0

        if (hasDohBoParams) {
          const handleParamResponse = await this.handleDohBoParam(dohBoParams)

          if (handleParamResponse.status === RESPONSE_STATUS.OK) {
            this.removeDohBoParams(dohBoParams)
          }
        }

        this.$router.push({ name: 'RegisterComplete' })
      } else {
        this.handleErrors(registerResponse)
      }
    },

    handleErrors (result) {
      this.errors = []

      if (result.status === 422) {
        // Back to tab form
        this.submitted = false
        // Scroll top
        this.scrollToTop()
        // Set errors server
        result.data.errors.forEach(x => {
          this.errors.push(x.message[0])
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .step {
    padding: 65px;
    @media #{$info-phone} {
      padding: 48px 18px;
    }
    &__title {
      text-align: center;
      h4 {
        font-family: $font-family-title;
        @include font-size(24px);
        color: #7C934C;
        margin-bottom: 20px;
        @media #{$info-phone} {
          font-size: 20px;
          margin-bottom: 16px;
        }
      }
      p {
        @include font-size(18px);
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 50px;
        @media #{$info-phone} {
         text-align: left;
          margin-bottom: 40px;
        }
      }
    }

    &__form {
      background-color: #E7E5DE;
      border-radius: 6px;
      margin-bottom: 60px;
      @media #{$info-phone} {
        margin-bottom: 45px;
      }
    }
    &__form__group {
      padding: 24px;
      @media #{$info-phone} {
        padding: 36px 18px;
      }
      .secret-question {
        margin-top: 6px
      }
      .form__control {
        display: flex;
        align-items: flex-start;
        @media #{$info-phone} {
          display: block;
        }
        label {
          width: 235px;
          flex-shrink: 0;
          @include font-size(18px);
          margin-top: 13px;
          @media #{$info-phone} {
            width: 100%;
            font-size: 16px;
            display: block;
            margin-bottom: 12px;
          }
          .required {
            display: none;
            color: #C66C44;
            @include font-size(14px);
            font-weight: bold;
            font-family: $font-family-title;
            margin-top: 16px;
            margin-left: 8px;
            @media #{$info-phone} {
              display: inline-block;
            }
          }
        }
        p {
          margin-top: 16px;
          margin-left: 50px;
          @media #{$info-phone} {
            margin-left: 0;
          }
        }
        &--specify {
          label {
            margin-top: 3px;
            @media #{$info-phone} {
              margin-top: 0;
            }
          }
          .form__control__input {
            .required {
              margin-top: 3px;
            }
          }
        }
        &--select {
          label {
            margin-top: 6px;
            @media #{$info-phone} {
              margin-top: 0;
            }
          }
          .form__control__input {
            .required {
              margin-top: 7px;
            }
          }
        }
      }
      .form__control:not(:last-child) {
        margin-bottom: 18px;
        @media #{$info-phone} {
          margin-bottom: 30px;
        }
      }
      .form__control__input {
        display: flex;
        align-items: flex-start;
        flex: 1;
        .required {
          color: #C66C44;
          @include font-size(14px);
          font-weight: bold;
          flex-shrink: 0;
          margin-right: 24px;
          margin-top: 16px;
          @media #{$info-phone} {
            display: none;
          }
        }
        .no-required {
          margin-left: 52px;
          @media #{$info-phone} {
            margin-left: 0;
          }
        }
        .people {
          margin-top: 12px;
          margin-left: 7px;
        }
        .radio__field {
          display: flex;
          flex-wrap: wrap;
          @media #{$info-phone} {
            label {
              display: inherit;
              margin-bottom: 0;
            }
          }
          input {
            flex-shrink: 0;
          }
          &>div {
            margin-right: 20px;
            @media #{$info-phone} {
              display: flex;
              align-items: center;
            }
          }
        }
        .postal_code,
        .birthday {
          @media #{$info-phone} {
            width: 50%;
          }
        }
      }

      .item {
        display: flex;
        align-items: center;
        @media #{$info-phone} {
          display: block;
        }
        label {
          width: 235px;
          flex-shrink: 0;
          @include font-size(18px);
          @media #{$info-phone} {
            width: 100%;
            font-size: 16px;
            display: block;
            margin-bottom: 12px;
          }
        }
        p {
          flex: 1;
          border-radius: 6px;
          background: rgba(255,255,255,.35);
          color: #282828;
          font-family: $font-family-title;
          @include font-size(20px);
          padding: 12px 16px;
          @media #{$info-phone} {
            font-size: 16px;
            padding: 10px 18px;
          }
        }
        ul {
          flex: 1;
          background: rgba(255,255,255,.35);
          border-radius: 6px;
          padding: 15px;
          li {
            color: #282828;
            display: block;
            font-size: 18px;
            @media #{$info-phone} {
              font-size: 16px;
            }
          }
        }
      }
      .item:not(:last-child) {
        margin-bottom: 18px;
        @media #{$info-phone} {
          margin-bottom: 30px;
        }
      }
    }
    &__form__group:not(:last-child) {
      border-bottom: 1px solid #ffffff;
    }
  }

  .rule {
    background: #E7E5DE;
    border-radius: 6px;
    text-align: center;
    padding: 35px 24px;
    margin-bottom: 54px;
    @media #{$info-phone} {
      padding: 35px 18px;
      font-size: 18px;
      text-align: left;
    }
    .rule__check {
      display: flex;
      align-items: flex-start;
      input {
        flex-shrink: 0;
        margin-right: 5px;
      }
      input:after {
        margin-top: 7px;
        @media #{$info-phone} {
          margin-top: 9px;
        }
      }
    }
    p {
      margin-bottom: 18px;
      @media #{$info-phone} {
        padding-bottom: 18px;
        border-bottom: 1px solid #ffffff;
      }
    }
    .err__policy {
      color: #cd201f;
      font-size: 12px;
      margin: 10px 0 0;
    }
  }

  .btn__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    width: 100%;
    border-radius: 6px;
    color: #ffffff;
    background: #6F8784;
    font-family: $font-family-title;
    @include font-size(18px);
    padding: 14px;
    margin: auto;
    cursor: pointer;
    @media #{$info-phone} {
      font-size: 16px;
      padding: 10px;
      max-width: 100%;
    }
    svg {
      margin-left: 5px;
    }
  }

  .btn__group {
    position: relative;
    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
    .btn__back {
      position: absolute;
      top: 12px;
      display: flex;
      align-items: center;
      @include font-size(16px);
      font-family: $font-family-title;
      color: #282828;
      @media screen and (max-width: 992px) {
        position: static;
        margin-top: 30px;
      }
      svg {
        margin-right: 5px;
      }
    }
  }
   .tabs-skip-info {
    overflow: hidden;
    .tab-skip-info {
      width: 100%;
      color: #282828;
      overflow: hidden;
      .tab-label-skip-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        background: #E7E5DE;
        font-weight: bold;
        cursor: pointer;
        &::after {
          content: "\276F";
          width: 1em;
          height: 1em;
          text-align: center;
          transition: all .55s;
        }
        @media #{$info-phone} {
          font-size: 12px;
          display: block;
          padding: 18px;
          &::after {
            display: block;
            margin: 5px auto 0;
            height: fit-content;
            font-size: 14px;
          }
        }
      }
      .desc-skip-regis {
        p {
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0;
            color: #FF0800;
          }
        }
      }
      .tab-content-skip-info {
        max-height: 0;
        color: #282828;
        background: #E7E5DE;
        transition: all .55s;
      }
    }
    #chck1 {
      position: absolute;
      opacity: 0;
      z-index: -1;
      &:checked {
        + .tab-label-skip-info {
          background: darken(#E7E5DE, 20%);
          &::after {
            transform: rotate(90deg);
          }
        }
        ~ .tab-content-skip-info {
          padding: 10px 0px 0px;
          max-height: fit-content;
          @media #{$info-phone} {
            padding: 5px 0px 0px;
          }
        }
      }
    }
  }
</style>
